import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getEvents, getTimeline, getRelatedTimelinesId } from '../../api/timeline.api';
import { EventsFictionDisplay } from './EventsFictionDisplay/EventsFictionDisplay';
import { Event_Interface } from '../../interfaces/Event.interface';
import styles from './FictionDisplay.module.scss';
import { Timeline_Interface } from '../../interfaces/Timeline.interface';
import { Helmet } from 'react-helmet';
import { faShareSquare, faThumbsUp, faCode, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loading } from '../../components/Loading/Loading';
import { TimelineCard } from '../../components/TimelineCard/TimelineCard/TimelineCard';
import { FictionCard } from '../../components/FictionCard/FictionCard';
import '../TimelineDisplay/TimelineModalShare/TimelineModalShare.css';
import { TimelineModalShare } from '../TimelineDisplay/TimelineModalShare/TimelineModalShare';
import { TimelineModalEmbed } from '../TimelineDisplay/TimelineModalEmbed/TimelineModalEmbed';
import { getUsersLikedTimeline, postLikeTimeline, removeLike } from '../../api/like.api';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { User_Interface } from '../../interfaces/User.interface';
import { getUser } from '../../api/user.api';
import { LikersModal } from '../../components/Modal/LikersModal/LikersModal';
import UserIcon from '../../assets/img/User_Icon.png';
import angleDown from '../../assets/img/down-arrow.png';
import angleUp from '../../assets/img/up-arrow.png';
import clickKeyboard from '../../assets/img/click-keyboard.png';
import clickMouse from '../../assets/img/click-mouse.png';
import swipe from '../../assets/img/swipe.png';
import { Transition } from 'react-transition-group';
import ReactToPrint from 'react-to-print';
import { EventFictionDisplayVertical } from './EventsFictionDisplay/EventFictionDisplayVertical';

export const FictionDisplayLayout: React.FunctionComponent = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    if (window.location.hostname === 'estory.io') ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [fiction, setFiction] = useState<Timeline_Interface>();

  const [events, setEvents] = useState<Event_Interface[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  const [like, setLike] = useState<boolean>(true);

  const [relatedTimelines, setRelatedTimelines] = useState<{ id: number }[]>();

  const { id } = useParams<{ id: string }>();

  const loggedUser = useSelector((state: RootState) => state.loggedUser);

  const [users, setUsers] = useState<User_Interface[]>([]);

  const [modalShow, setModalShow] = useState<boolean>(false);

  const [user, setUser] = useState<User_Interface>();

  const [mediaShow, setMediaShow] = useState<boolean>(false);

  const [embedShow, setEmbedShow] = useState<boolean>(false);

  const [showRelatedRecommended, setShowRelatedRecommended] = useState<boolean>(false);

  const [copied, setCopied] = useState<boolean>(false);

  const history = useHistory();

  const [style, setStyle] = useState({
    paddingTop: '60px',
  });

  const [firstVisit, setFirstVisit] = useState<boolean>(false);

  const [inProp, setInProp] = useState(true);

  const [mode, setMode] = useState<boolean>(false);

  const componentRef = useRef(null);

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return 'mobile';
    }
    return 'desktop';
  };

  const duration = 200;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles: any = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  function setShowRelatedRecommended2(bool: boolean) {
    setShowRelatedRecommended(bool);
  }
  function addEvent(event: Event_Interface) {
    setEvents((prevEvent) => [...prevEvent, event]);
  }

  const delLike = async () => {
    setUsers(users.filter((u) => loggedUser?.id !== u.id));
    await removeLike(Number(fiction?.id));
    setLike(true);
  };

  const likeTimeline = async () => {
    setUsers(user ? users.concat([user]) : users);
    await postLikeTimeline(Number(fiction?.id));
    setLike(true);
  };

  const goToLogin = () => {
    const url = '/login';
    history.push(url);
  };

  const modifyFiction = () => {
    const url = '/fiction/editFiction/' + fiction?.id + '/' + fiction?.title;
    history.push(url);
  };

  const showModal = () => {
    if (fiction?.nbLike !== 0) {
      setModalShow(true);
    }
  };

  const isFictionRelated = (id: number) => {
    let t;
    const timeline = getTimeline(Number(id)).then((timelineFictionOrNot) =>
      timelineFictionOrNot.type_story === 'fiction' ? (t = true) : (t = false),
    );
    return t;
  };

  useEffect(() => {
    window.addEventListener('keyup', () => {
      setInProp(false);
    });
    window.addEventListener('keydown', () => {
      setInProp(false);
    });
    const firstVisit = localStorage.getItem('wasVisited') === null;
    setFirstVisit(firstVisit);
    if (firstVisit) {
      localStorage.setItem('wasVisited', String(1));
    }
  }, []);

  const hideProductTour = () => {
    if (inProp) {
      setInProp(false);
    }
  };

  useEffect(() => {
    if (like) {
      (async () => {
        getTimeline(Number(id)).then((fiction) => setFiction(fiction));

        const userLikes = await getUsersLikedTimeline(Number(id));
        const usersWhoLiked: Array<User_Interface> = [];

        await Promise.all(
          userLikes.map(async (user) => {
            await getUser(user.id_user).then((result) => {
              usersWhoLiked.push(result);
            });
          }),
        );

        setUsers(usersWhoLiked.slice(0));
        setLike(false);
      })();
    }
  }, [like]);

  useEffect(() => {
    if (loading) {
      (async () => {
        getTimeline(Number(id)).then(
          (fiction) => setFiction(fiction),
          () => history.push('/home'),
        );

        const events = await getEvents(Number(id));
        events.forEach((event) => {
          addEvent(event);
        });

        const relatedTimelines = await getRelatedTimelinesId(Number(id));
        setRelatedTimelines(relatedTimelines);

        setLoading(false);
      })();
    }
  }, [loading]);

  useEffect(() => {
    if (!mediaShow) {
      setCopied(false);
    }
  }, [mediaShow]);

  // Can be extracted in hook
  // https://reactjs.org/docs/hooks-custom.html
  useEffect(() => {
    if (location.pathname.split('/')[1] === 'api') {
      setStyle({
        paddingTop: '0px',
      });
    } else {
      setStyle({
        paddingTop: '60px',
      });
    }

    getUser(parseInt('' + loggedUser?.id)).then((result) => {
      setUser(result);
    });
  }, []);

  useEffect(() => {
    setFiction(undefined);
    setEvents([]);
    setRelatedTimelines([]);
    setUsers([]);
    setLike(true);
    setLoading(true);
  }, [id]);

  useEffect(() => {
    const fictionTitle = fiction?.title.replaceAll(' ', '-');
    const currentURL = `${window.location.pathname}`;
    const canonicalURL = `/fiction/${id}/${fictionTitle}`;
    if (fictionTitle === undefined) {
      return;
    } else if (currentURL !== canonicalURL) {
      window.location.replace(canonicalURL);
    }
  }, [id, fiction, history]);

  return (
    <>
      <Helmet>
        <title>
          {fiction?.title
            ? `${fiction?.title} by ${fiction?.UserModels[0].firstname} ${fiction?.UserModels[0].lastname}`
            : 'eStory | Visualise the key events of stories'}
        </title>
        {fiction?.visible ? null : <meta name="robots" content="noindex" />}
        <meta property="og:type" content="siteweb" />
        <meta
          name="twitter:title"
          content={
            fiction?.title
              ? `${fiction?.title} by ${fiction?.UserModels[0].firstname} ${fiction?.UserModels[0].lastname}`
              : `eStory`
          }
        ></meta>
        <meta
          name="description"
          content={
            fiction?.title
              ? `Discover ${fiction?.title}. The timeline was created by ${fiction?.UserModels[0].firstname} ${fiction?.UserModels[0].lastname}`
              : `Discover the timeline on eStory.`
          }
        />
        <meta
          property="og:title"
          content={
            fiction?.title
              ? `${fiction?.title} by ${fiction?.UserModels[0].firstname} ${fiction?.UserModels[0].lastname}`
              : `eStory`
          }
        />
        <meta
          property="og:image"
          content={
            fiction?.img_background
              ? fiction?.img_background
              : `https://res.cloudinary.com/dk2ghb1pg/image/upload/v1531942668/Logo_eStory_simple_klalco.png`
          }
        />
        <meta property="og:url" content={`https://${window.location.hostname}/fiction/${id}/${fiction?.title}`} />
        <link
          rel="canonical"
          href={`https://${window.location.hostname}/fiction/${id}/${fiction?.title.replaceAll(' ', '-')}`}
        />
      </Helmet>

      {firstVisit ? (
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <div
                className={styles.productTour}
                onClick={hideProductTour}
                onTouchStart={hideProductTour}
                onTouchMove={hideProductTour}
                onTouchEnd={hideProductTour}
              >
                {getDeviceType() === 'desktop' ? (
                  <div className={styles.productTour2}>
                    <div style={{ color: 'white' }}>
                      <h2>Click to go to the next or previous event</h2>
                    </div>
                    <img src={clickMouse} className={styles.productTourImageDesktop} width="auto" />
                    <div className={styles.productTourTextDesktop} style={{ color: 'white' }}>
                      <h4>Or use keyboard to navigate</h4>
                    </div>
                    <img src={clickKeyboard} className={styles.productTourImageDesktop} width="auto" />
                  </div>
                ) : (
                  <div className={styles.productTour3}>
                    <div style={{ color: 'white' }}>
                      <h2>Swipe to go to the next or previous event</h2>
                    </div>
                    <img src={swipe} className={styles.productTourImageDesktop} width="auto" />
                  </div>
                )}
              </div>
            </div>
          )}
        </Transition>
      ) : (
        <></>
      )}
      <div
        className={styles.backgroundImage}
        style={{
          ...style,
          backgroundImage: `url(${fiction?.img_background})`,
        }}
      />

      {getDeviceType() === 'mobile' ? (
        <div className={styles.container}>
          {!showRelatedRecommended ? (
            <div className={styles.timeline}>
              {!loading ? (
                <>
                  <div className={styles.timelineTitle}>
                    <div className={`${styles.title}`}>{fiction?.title}</div>
                    {!like ? (
                      <>
                        <div className={styles.popupStyle}>
                          <div className={styles.likeButton}>
                            <FontAwesomeIcon
                              icon={faCode}
                              style={{ width: 'auto' }}
                              color={'white'}
                              className={styles.likeButtonIcon}
                              onClick={() => setEmbedShow(true)}
                            />
                          </div>
                          <TimelineModalEmbed id={id} show={embedShow} onHide={() => setEmbedShow(false)} />
                          <div className={styles.likeButton}>
                            <FontAwesomeIcon
                              style={{ width: 'auto' }}
                              icon={faShareSquare}
                              color={'white'}
                              className={styles.likeButtonIcon}
                              onClick={() => setMediaShow(true)}
                            />
                          </div>
                          <TimelineModalShare
                            id={id}
                            show={mediaShow}
                            copied={copied}
                            title={fiction?.title}
                            setCopied={() => setCopied(true)}
                            onHide={() => setMediaShow(false)}
                          />

                          <div className={styles.likeButton}>
                            {!loggedUser ? (
                              <FontAwesomeIcon
                                icon={faThumbsUp}
                                color={'white'}
                                className={styles.likeButtonIcon}
                                onClick={() => goToLogin()}
                              />
                            ) : users.some((u) => u.id === loggedUser.id) ? (
                              <FontAwesomeIcon
                                icon={faThumbsUp}
                                color={'white'}
                                className={styles.likeButtonIcon2}
                                onClick={() => delLike()}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faThumbsUp}
                                color={'white'}
                                className={styles.likeButtonIcon}
                                onClick={() => likeTimeline()}
                              />
                            )}
                            <div className={`${styles.title} ${styles.likeButtonText}`} onClick={() => showModal()}>
                              {users.length}
                              {users.length === 0 ? null : (
                                <div className={styles.userList}>
                                  {users.map((user: User_Interface, key) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <div
                                      className={styles.namesList}
                                      key={key}
                                    >{`${user.firstname} ${user.lastname}`}</div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.popupStyle}>
                          <TimelineModalShare
                            id={id}
                            show={mediaShow}
                            copied={copied}
                            title={fiction?.title}
                            setCopied={() => setCopied(true)}
                            onHide={() => setMediaShow(false)}
                          />
                          <div className={styles.likeButton}>
                            {!loggedUser ? (
                              <FontAwesomeIcon
                                icon={faThumbsUp}
                                color={'white'}
                                className={styles.likeButtonIcon}
                                onClick={() => goToLogin()}
                              />
                            ) : users.some((u) => u.id === loggedUser.id) ? (
                              <FontAwesomeIcon
                                icon={faThumbsUp}
                                color={'white'}
                                className={styles.likeButtonIcon2}
                                onClick={() => delLike()}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faThumbsUp}
                                color={'white'}
                                className={styles.likeButtonIcon}
                                onClick={() => likeTimeline()}
                              />
                            )}
                            <div className={`${styles.title} ${styles.likeButtonText}`} onClick={() => showModal()}>
                              {users.length}
                              {users.length === 0 ? null : (
                                <div className={styles.userList}>
                                  {users.map((user: User_Interface, key) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <div
                                      className={styles.namesList}
                                      key={key}
                                    >{`${user.firstname} ${user.lastname}`}</div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {loggedUser?.id === fiction?.UserModels[0].id ? (
                      <button className={styles.modifyButton} onClick={() => modifyFiction()}>
                        Edit
                      </button>
                    ) : null}
                  </div>
                  <div className={`${styles.title} ${styles.author}`}>
                    <a
                      className={`${styles.profileLink}`}
                      href={
                        '/user/' +
                        fiction?.UserModels[0].id +
                        '/' +
                        fiction?.UserModels[0].firstname.replaceAll(' ', '-') +
                        '-' +
                        fiction?.UserModels[0].lastname.replaceAll(' ', '-')
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={fiction?.UserModels[0].picture ? fiction?.UserModels[0].picture : UserIcon}
                        className={styles.authorImage}
                      />
                      <div className={styles.authorText}>
                        {`By ${fiction?.UserModels[0].firstname} ${fiction?.UserModels[0].lastname}`}
                      </div>
                    </a>
                  </div>
                  <div className={styles.eventsContainer}>
                    <div className={styles.verticalTimelineMobile}>
                      <EventFictionDisplayVertical events={events} />{' '}
                    </div>
                    <div
                      className={styles.relatedRecommended2}
                      onClick={() => {
                        setShowRelatedRecommended2(!showRelatedRecommended);
                      }}
                    >
                      <div className={styles.iconbackground}>
                        <img src={angleDown} className={styles.angleDown} />
                      </div>
                      <h4 className={styles.titleTimeline}> See media recommendations</h4>
                    </div>
                  </div>
                </>
              ) : (
                <div className={styles.eventsContainer2}>
                  <div className={styles.loading}>
                    <Loading />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.mediaRecommended}>
              <div
                className={styles.relatedRecommended}
                onClick={() => {
                  setShowRelatedRecommended2(!showRelatedRecommended);
                }}
              >
                <h4 className={styles.titleTimeline}> Go back to the timeline</h4>
                <div className={styles.iconbackground}>
                  <img src={angleUp} className={styles.angleDown} />
                </div>
              </div>
              <p className={`${styles.title} ${styles.recommendedTitle}`}>Related to this story</p>
              {relatedTimelines?.length === 0 && (
                <p style={{ fontStyle: 'italic', color: 'white' }}> No timeline related found </p>
              )}
              <>
                {relatedTimelines?.map((relatedTimeline, index) => {
                  if (isFictionRelated(relatedTimeline.id)) {
                    return <FictionCard key={index} id={relatedTimeline.id} reduced marginBottom={'10px'} />;
                  } else {
                    return <TimelineCard key={index} id={relatedTimeline.id} reduced marginBottom={'10px'} />;
                  }
                })}
              </>
            </div>
          )}
          <LikersModal users={users} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      ) : (
        /* fin du 2ème if */
        <div className={styles.pagePC}>
          <div className={mode ? styles.timelineVerticalPC : styles.timelinePC}>
            {!loading ? (
              <>
                <div className={styles.timelineTitle}>
                  <div className={`${styles.titlePC}`}>{fiction?.title}</div>

                  <>
                    <div className={styles.likeButtonPC}>
                      <FontAwesomeIcon
                        icon={faCode}
                        style={{ width: 'auto' }}
                        color={'white'}
                        className={styles.likeButtonIcon}
                        onClick={() => setEmbedShow(true)}
                      />
                    </div>
                    <TimelineModalEmbed id={id} show={embedShow} onHide={() => setEmbedShow(false)} />
                    <div className={styles.likeButtonPC}>
                      <FontAwesomeIcon
                        style={{ width: 'auto' }}
                        icon={faShareSquare}
                        color={'white'}
                        className={styles.likeButtonIcon}
                        onClick={() => setMediaShow(true)}
                      />
                    </div>
                    <TimelineModalShare
                      id={id}
                      show={mediaShow}
                      copied={copied}
                      title={fiction?.title}
                      setCopied={() => setCopied(true)}
                      onHide={() => setMediaShow(false)}
                    />

                    <div className={styles.likeButtonPC}>
                      {!loggedUser ? (
                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          color={'white'}
                          className={styles.likeButtonIcon}
                          onClick={() => goToLogin()}
                        />
                      ) : users.some((u) => u.id === loggedUser.id) ? (
                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          color={'white'}
                          className={styles.likeButtonIcon2}
                          onClick={() => delLike()}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          color={'white'}
                          className={styles.likeButtonIcon}
                          onClick={() => likeTimeline()}
                        />
                      )}
                      <div className={`${styles.titlePC} ${styles.likeButtonText}`} onClick={() => showModal()}>
                        {users.length}
                        {users.length === 0 ? null : (
                          <div className={styles.userList}>
                            {users.map((user: User_Interface, key) => (
                              // eslint-disable-next-line react/jsx-key
                              <div className={styles.namesList} key={key}>{`${user.firstname} ${user.lastname}`}</div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                  {mode ? (
                    <div className={styles.horizontalVerticalButton}>
                      <button className={styles.setHorizontalButton} onClick={() => setMode(false)}>
                        horizontal
                      </button>
                      <button className={styles.setVerticalButtonSelected} onClick={() => setMode(true)}>
                        vertical
                      </button>
                    </div>
                  ) : (
                    <div className={styles.horizontalVerticalButton}>
                      <button className={styles.setHorizontalButtonSelected} onClick={() => setMode(false)}>
                        horizontal
                      </button>
                      <button className={styles.setVerticalButton} onClick={() => setMode(true)}>
                        vertical
                      </button>
                    </div>
                  )}
                  {loggedUser?.id === fiction?.UserModels[0].id ? (
                    <button className={styles.modifyButton} onClick={() => modifyFiction()}>
                      Edit
                    </button>
                  ) : null}

                  {mode ? (
                    <div>
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <div className={styles.likeButtonPC}>
                              <FontAwesomeIcon
                                icon={faPrint}
                                style={{ width: 'auto' }}
                                color={'white'}
                                className={styles.likeButtonIcon}
                              />
                            </div>
                          );
                        }}
                        content={() => componentRef?.current}
                        documentTitle={`${fiction?.title} - By ${fiction?.UserModels[0].firstname} ${fiction?.UserModels[0].lastname} `}
                        pageStyle="print"
                      />
                    </div>
                  ) : null}
                </div>
                <div className={`${styles.titlePC} ${styles.author}`}>
                  <a
                    className={`${styles.profileLink}`}
                    href={
                      '/user/' +
                      fiction?.UserModels[0].id +
                      '/' +
                      fiction?.UserModels[0].firstname.replaceAll(' ', '-') +
                      '-' +
                      fiction?.UserModels[0].lastname.replaceAll(' ', '-')
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={fiction?.UserModels[0].picture ? fiction?.UserModels[0].picture : UserIcon}
                      className={styles.authorImage}
                    />
                    <div className={styles.authorText}>
                      {`By ${fiction?.UserModels[0].firstname} ${fiction?.UserModels[0].lastname}`}
                    </div>
                    <div className={styles.creationDateText}>
                      {`Created on ${new Date(fiction?.timeline_created_at ?? '').toLocaleDateString()}`}
                    </div>
                  </a>
                </div>

                {mode ? (
                  <div className={styles.verticalTimelinePC} ref={componentRef}>
                    <EventFictionDisplayVertical events={events} />
                  </div>
                ) : (
                  <div className={styles.eventsContainersPC}>
                    <div className={styles.eventsContainerPC}>
                      <EventsFictionDisplay events={events} />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className={styles.eventsContainerPC}>
                <div className={styles.loading}>
                  <Loading />
                </div>
              </div>
            )}
          </div>

          <div className={styles.relatedRecommendedPC}>
            <div className={styles.mediaRecommended}>
              <p className={`${styles.titlePC} ${styles.recommendedTitle}`}>Related to this story</p>
              {relatedTimelines?.length === 0 && (
                <p style={{ fontStyle: 'italic', color: 'white' }}> No timeline related found </p>
              )}
              <>
                {relatedTimelines?.map((relatedTimeline, index) => {
                  if (isFictionRelated(relatedTimeline.id)) {
                    return <FictionCard key={index} id={relatedTimeline.id} reduced marginBottom={'10px'} />;
                  } else {
                    return <TimelineCard key={index} id={relatedTimeline.id} reduced marginBottom={'10px'} />;
                  }
                })}
              </>
            </div>
          </div>
          <LikersModal users={users} show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      )}
    </>
  );
};
